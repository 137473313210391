.user-item {
    min-width: 200px;
    max-width: 280px;
}

.user-name {
    max-width: 57%;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}