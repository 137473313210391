.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-sb-top {
    display: flex;
    justify-content: space-between;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.center-container {
    width: 100%;
    /*height: 80vh;
    max-height: 80vh;*/
    /*position: fixed;*/
    bottom: 0px;
    display: flex;
    justify-content: center;
}

.user-list-container {
    position: relative;
    top: 0px;
    left: 0px;
    margin: 15px;
}
/* Alaa Duksi CSS */
.bg-color-boxs {
    background-color: #efefef;
    padding: 0!important;
    margin: 0!important;
}
.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}
.center-container > div {
    border: solid 1px #ccc;
}
.center-container h1 {
    color: #737272;
    font-size: 22px!important;
    padding-left: 16px;
    padding-top: 10px;
    margin-bottom: 0!important;
}
.bg-color-boxs .mylist {
    margin-top: 18px;
}
.bg-color-boxs ul {
    padding: 18px 0 0 0;
    margin: 0;
}
.bg-color-boxs ul li {
    list-style: none;
    color: #737272;
    padding: 7px 1px;
}
.bg-color-boxs ul li a {
    color: #737272;
    text-decoration: none;
    padding: 10px 17% 9px 17px;
}
.bg-color-boxs ul li a:hover {
    color: #585656;
}
.bg-color-boxs ul li.select {
    background-color: #fff;
    border-bottom: solid 1px #c2c2c2;
    border-top: solid 1px #c2c2c2;
    font-weight: 450;
}
.dropdown button:focus {outline:0;}

.container-add-icon {
 margin: 15px 0px;
 margin: 15px 0px;
 padding-bottom: 35px;

}
.container-add-icon > div {
    float: left;
}
.dropdown {
    float: left;
    margin-right: 24px;
}
.buttonCollr,.buttonCollr:active,.buttonCollr:focus {
    background-color: #6c757d!important;
    border: 0!important;
    outline: 0!important;
    border: none!important;

}
.userButton > div {
    width: 72px;
    float: left;
}
.right {
    padding-left: 76px;
}
.page-link.disable {
    pointer-events: none;
}
.page-link.disable:hover {
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}
.card-title {
    color: #737272!important;
}
.card-header {
    padding: 0.97rem 1.25rem;
    background-color: #efefef;
}
.del {
    background-image: url(img/dele.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 22px;
    padding: 12px;
    float: right;
    margin-right: 6px;
}
.user-list {
    position: absolute;
    z-index: 1000;
    width: 304px;
    right: 44%;
}
.search {
    outline: none;
}
.search {
    width: 144px;
}