.data-source-item {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.data-source-item-button-container {
    display: flex;
}

.data-sources-list-container { 
    max-width: 400px;
    min-width: 300px;
}