.container {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100vh;
    justify-content: center;
}

.login-form {
    max-width: 350px;
    width: 350px;
}