.empty-list-text {
    text-align: center;
}

.list-header {
    background: #efefef;
    height: 47px;
    margin: 0;
}

.editable-list {
    max-width: 100%;
}

.editable-list-item-name {
    width: 60%;
    max-width: 60%;
}

.white-link {
    color: white;
}
.underline-link {
    text-decoration: underline;
}