.items-container {
    height: stretch;
}

.list-container {
    width: 500px;
    max-height: 100%;
}

.body-container {
    height: calc(100% - 47px);
    overflow: auto;
}

.report-link-container {
    width: 50%;
}

.report-link {
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    color: #000;
}
.report-link a {
	color: #58C3BE;
}
.product-overview button,.product-overview button:hover,.page-link,.page-link:hover {
	color: #58C3BE;	
}
.user-view {
    height: 35px;
}